// css variables
const bpMdS = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-md-s');
const bpMdX = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-md-x');
const bpMd = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-md');
const bpLg = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-lg');
const bpMg = getComputedStyle(document.documentElement)
    .getPropertyValue('--bp-mg');


// media queries
let mq = {
    mdS: window.matchMedia('(min-width: ' + bpMdS + ')'),
    md: window.matchMedia('(min-width: ' + bpMd + ')'),
    mdX: window.matchMedia('(min-width: ' + bpMdX + ')'),
    lg: window.matchMedia('(min-width: ' + bpLg + ')'),
    mg: window.matchMedia('(min-width: ' + bpMg + ')')
};

export { mq };