// slick-slider
// ---------------------------------------------------------------------------
$(function () {
  $(".js-keyvisual-slider").slick({
    adaptiveHeight: false,
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    speed: 2000
  });
});

// ================================================
// Attach custom click event on cloned elements, 
// trigger click event on corresponding link.
// Note: This will work for all sliders on the page
// ================================================
$(document).on('click', '.slick-cloned', function(e) {
  var $slides = $(this)
    .parent()
    .children('.slick-slide:not(.slick-cloned)');

  $slides
    .eq( ( $(this).attr("data-slick-index") || 0) % $slides.length )
    .trigger("click.fb-start", { $trigger: $(this) });

  return false;
});

$(document).on('beforeChange', '.js-keyvisual-slider', function(event, slick, currentSlide, nextSlide){
    var nextSlide = slick.$slides.get(nextSlide);
    var $slideSoureSets = $(nextSlide).find('source');
    $($slideSoureSets).each(function () {
        $(this).attr('srcset', $(this).data('lazy'));
    });
    //console.log(nextSlide);
  });