// variables
import { mq } from './variables.js';

require('slick-carousel');
require('./slick-slider.js');
require('@fancyapps/fancybox');
//require('./fancybox.js');
require('lazyload');
require('./weBranding.js');
//require('./disclosureMenu.js');
//require('./menubar-navigation.js');

//require('./Menubar/MenubarLinks.js');
//require('./Menubar/MenubarItemLinks.js');
//require('./Menubar/PopupMenuLinks.js');
//require('./Menubar/PopupMenuItemLinks.js');
//require('./fly-out-menu.js');
//
import { DisclosureMenu } from "accessible-menu";

document.addEventListener("DOMContentLoaded", () => {
  const containerElement = document.querySelector(".c-nav-main");
  const controllerElement = containerElement.querySelector("button"); 
  const menuElement = containerElement.querySelector("ul");

  const menu = new DisclosureMenu({
    menuElement,
    submenuItemSelector: ".dropdown",
    containerElement,
    controllerElement,
    optionalKeySupport: true,
    //hoverType: 'dynamic'
  });
});

//require('./main.js');

lazyload();